const directive = {
  mounted(el, binding) {


    const bar = el.querySelector('.el-scrollbar__bar')

    if (bar && el) {
      bar.classList.add('v-scroll__el-scrollbar__bar')
      const app = document.querySelector('.admin-box')
      app.appendChild(bar)
    }
  },

  beforeUnmount(el, binding, vnode, prevVnode) {
    const scrollbar = document.querySelector('.v-scroll__el-scrollbar__bar')
    const app = document.querySelector('.admin-box')
    if (scrollbar) app.removeChild(scrollbar)
  },


}

export default directive
