/**
 * 网站配置文件
 */

const config = {
  appName: 'UPDF管理后台',
  appLogo: 'https://www.updf.cn/wp-content/themes/updf-3/images/logo.png',
  showViteLogo: true,
}

export const viteLogo = env => {
  if (config.showViteLogo) {
    const chalk = require('chalk')
    console.log(chalk.green(`> 欢迎使用UPDF管理后台`))
    console.log(chalk.green(`> 当前版本:框架v2.5.4，系统1.0.0`))
    console.log(chalk.green(`> 插件市场:https://plugin.gin-vue-admin.com`))
    console.log(chalk.green(`> 默认自动化文档地址:http://127.0.0.1:${env.VITE_SERVER_PORT}/swagger/index.html`))
    console.log(chalk.green(`> 默认前端文件运行地址:http://127.0.0.1:${env.VITE_CLI_PORT}`))
    console.log('\n')
  }
}

export default config
